body {
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.construction-page {
  text-align: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.construction-page h1 {
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #333;
}

.construction-page p {
  font-size: 1rem;
  color: #666;
}
